import Image from "@/components/Image"
import { API_URL } from "@/config"
import { IOrderItem } from "@/types"
import { priceMasking } from "@/utils/helpers"

const Order = ({
    data
}: {
    data: IOrderItem
}) => {

  return (
    <div className="p-3 flex gap-3 w-full">
        <div className="w-16 h-16 lg:h-20 lg:w-20 shrink-0 rounded-md border border-border overflow-hidden">
            <Image
                src={`${API_URL}/${data.image}`}
                className="w-full h-full object-contain"
            />
        </div>
        <div className="space-y-2">
            <p className="text-sm font-medium hover:underline">{data.name}</p>
            <p className="text-thirthGold font-semibold text-sm">{`Adet: ${data.quantity}`} <br /> {priceMasking(data.price)}</p>
            {data.combination && 
                (<div className="text-xs font-medium space-y-1">
                    {/* <b className="text-secondary-foreground">Özellik</b> */}
                    <div className="flex gap-1 flex-wrap">
                        {data.combination?.variant_values?.map((v, index) => (
                            <p key={index} className="px-3 py-1 rounded-md border border-border">
                            {v.value}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </div>
    </div>
  )
}

export default Order