/* eslint-disable @typescript-eslint/no-explicit-any */
import MainWrapper from "@/components/MainWrapper"
import Menu from "./components/Menu"
import { useCallback, useEffect, useState } from "react"
import request from "@/utils/request"
import { IOrder } from "@/types"
import { PaginationComp } from "@/components/PaginationComp"
import Heading from "@/components/Heading"
import Icon from "@/components/icon"
import Order from "./components/Order"
import OrderLoading from "./components/OrderLoading"
import Empty from '@/assets/icons/empty-box.svg'

const Orders = () => {

  const [filter, setFilter] = useState({
    orderId: '',
    status: '',
    payment: ''
  })
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(1)
  const [page, setPage] = useState(1)
  const [orders, setOrders] = useState<IOrder[]>([])

  const handleOrders = useCallback(async() => {
    try {
      setLoading(true)
      const form : {orderId?: number, status?:number, payment_success?:string, limit?:number, page?:number} = {}
      if(filter.orderId) form.orderId = Number(filter.orderId)
      if(filter.status) form.status = Number(filter.status)
      if(filter.payment) form.payment_success = filter.payment
      form.page = page
      form.limit = 20
      const res = await request({url: '/admin/orders', data: form})
      setOrders(res.data.orders)
      setTotal(res.data.totalOrders)
      setTotalPages(res.data.totalPages)
    } catch (error: any) {
      throw new Error(error)
    } finally {
      setLoading(false)
    }
  }, [filter.orderId, filter.payment, filter.status, page])

  useEffect(() => {
    handleOrders()
  }, [handleOrders])
  
  const getSubTitle = (status: number) => {
    switch (status) {
      case 1:
        return "Siparişiniz bulunmamaktadır.";
      case 2:
        return "Hazırlanan siparişiniz bulunmamaktadır.";
      case 3:
        return "Kargoya verilen siparişiniz bulunmamaktadır.";
      case 4:
        return "Teslim edilen siparişiniz bulunmamaktadır.";
      case 5:
      case 6:
        return "İptal edilen siparişiniz bulunmamaktadır.";
      default:
        return "Devam eden siparişiniz bulunmamaktadır.";
    }
  };

  return (
    <MainWrapper>
      <Menu
        total={total}
        setFilter={setFilter}
      />
      <div className="w-full h-full hideScrollRes lg:pr-2 overflow-y-auto space-y-5">
        {
          !loading ?
          orders.length > 0 ?
          orders?.map((v, i) => (
            <Order
              key={i}
              data={v}
            />
          ))
        :
        <div className="w-full h-full flex items-center flex-col gap-5 justify-center">
            <Icon
              icon={<Empty/>}
              width="150px"
              height="150px"
            />
            <Heading
              title="Sonuç Bulunamadı"
              subTitle={getSubTitle(parseInt(filter.status) || 1)}
              center
            />
        </div>
        :
        [...Array(5)].map((_, i) => (
          <OrderLoading
            key={i}
          />
        ))
      }
      </div>
      {totalPages > 1 &&
      (<div className="w-full overflow-x-auto hideScroll flex items-center justify-center">
      <PaginationComp
            totalPages={totalPages}
            setPage={setPage}
            currentPage={page}
        />
      </div>)}
    </MainWrapper>
  )
}

export default Orders