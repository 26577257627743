import Hourglass from '@/assets/icons/hourglass.svg'
import Truck from '@/assets/icons/truck.svg'
import Success from '@/assets/icons/tick.svg'
import Cancel from '@/assets/icons/cancel.svg'
import CancelOrder from '@/assets/icons/cancel_order.svg'
import Step from '@/assets/icons/step.svg'
import Bill from '@/assets/icons/bill.svg'
import Shipping from '@/assets/icons/shipping-man.svg'
import Icon from '@/components/icon'
import { IOrder } from "@/types";
import { formatDate } from "@/utils/helpers";
import { AlertDialogComp } from '@/components/Alert'
import { useToast } from '@/components/ui/use-toast'
import request from '@/utils/request'

const Buttons = ({
    order,
    getData
}: {
    order: IOrder | undefined
    getData: () => void;
}) => {
    const {toast} = useToast()

    const getIcon = (statusId: number) => {
        switch (statusId) {
          case 1:
          case 2:
            return <Hourglass />;
          case 3:
            return <Truck />;
          case 4:
            return <Success />;
          case 5:
          case 6:
            return <Cancel />;
          default:
            return null;
        }
    };

    const handleNextStep = async () => {
        if(order) {
            try {
                const res = await request({url: '/order/update_status', data: {newStatus: order.status.id + 1, orderId: order.id}})
                toast({title: "İşlem Başarılı.",
                    description: res.data.message
                })
                getData()
            } catch (error) {
                const err = error as { status: number, response: { data: { message: string } } };
                toast({
                    title: "İşlem Başarısız!",
                    description: err.response.data.message
                })
            }
        }
    };

    const handleCancelOrder = async() => {
        try {
            const res = await request({url: `/order/cancelOrderByAdmin?order_id=${order?.id}`})
            toast({title: "İşlem Başarılı.",
                description: res.data.message
            })
            getData()
        } catch (error) {
            const err = error as { status: number, response: { data: { message: string } } };
                toast({
                    title: "İşlem Başarısız!",
                    description: err.response.data.message
                })
        }
    }

  return (
    <div className="">
        <div className="lg:py-5 p-3 lg:px-8 flex gap-3 border-b border-border">
            <div className="w-[50px] h-[50px] rounded-full bg-lightBg dark:bg-secondary shrink-0 flex items-center justify-center">
                <Icon
                    width="25px"
                    height="30px"
                    icon={getIcon(order?.status.id || 1)}
                />
            </div>
            <div className="flex flex-col gap-1 text-xs lg:text-sm">
                <p className="font-semibold">{order?.status.name}</p>
                {order?.status.id === 1 && 
                (<p className="text-thirthGold">Tarih: <b>{formatDate(new Date(order!.createdAt))}</b></p>)
                }
                {order?.status.id === 3 && order?.shipping_date ?
                (<p className="text-thirthGold">Tarih: <b>{formatDate(new Date(order!.shipping_date))}</b></p>)
                : null}
                {order?.status.id === 4 && order?.complate_date ?
                (<p className="text-thirthGold">Tarih: <b>{formatDate(new Date(order!.complate_date))}</b></p>)
                : null}
            </div>
        </div>
        <div className="lg:py-5 p-3 lg:px-8 grid grid-cols-2 1420:grid-cols-3 gap-3 xl:gap-5">
            <AlertDialogComp
                buttonClass="w-full"
                handleAccept={handleNextStep}
                title="Bir Sonraki Aşamaya Geçmek İstediğinize Emin Misiniz?"
                customButton={
                <button
                    disabled={order && order?.status.id >= 4}
                    className="order-button bg-gradient-to-r from-blue-400 to-blue-700 hover:opacity-80">
                        <Icon
                            icon={<Step/>}
                            width="25px"
                            height="20px"
                            color='#fff'
                        />
                        <p>Bir Sonraki Aşamaya Geç</p>
                    </button>
                }
                />
                <AlertDialogComp
                buttonClass="w-full"
                handleAccept={handleCancelOrder}
                title="Siparişi İptal Etmek İstediğinize Emin Misiniz?"
                customButton={
                    <button 
                    disabled={order && order?.status.id > 2}
                    className="order-button bg-gradient-to-r from-red-400 to-red-700 hover:opacity-80">
                        <Icon
                            icon={<CancelOrder/>}
                            width="18px"
                            height="20px"
                            color='#fff'
                        />
                        <p>İptal Et</p>
                    </button>
                }
                />
            <button
            className="order-button bg-gradient-to-r from-green-400 to-green-700 hover:opacity-80">
                <Icon
                    icon={<Bill/>}
                    width="18px"
                    height="20px"
                    color='#fff'
                />
                <p>Fatura Yükle</p>
            </button>
            <button
            disabled={order && order.status.id !== 3}
            className="order-button bg-gradient-to-r from-orange-400 to-orange-700 hover:opacity-80">
                <Icon
                    icon={<Shipping/>}
                    width="18px"
                    height="20px"
                    color='#fff'
                />
                <p>Kargo Bilgisi Gir</p>
            </button>
        </div>
    </div>
  )
}

export default Buttons