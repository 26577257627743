/* eslint-disable @typescript-eslint/no-explicit-any */
import SelectComp from "@/components/SelectComp"
import { Input } from "@/components/ui/input"
import request from "@/utils/request"
import { Filter, FilterX } from "lucide-react"
import { useCallback, useEffect, useState } from "react"

const Menu = ({
    setFilter,
    total
}: {
    setFilter: React.Dispatch<React.SetStateAction<{
        orderId: string;
        status: string;
        payment: string;
    }>>
    total: number;
}) => {

    const [statusArr, setStatusArr] = useState<{id: number, name: string}[]>([])
    const getStatus = useCallback(async () => {
        try {
            const res = await request({url: '/filters/status', method: 'get'})
            setStatusArr(res.data)
        } catch (error:any) {
            throw new Error(error)
        }
    }, [])

    useEffect(() => {
        getStatus()
    }, [getStatus])

    const [filters, setFilters] = useState({
        orderId: '',
        status: '',
        payment: ''
    })

    const handleFilter = () => {
        setFilter(filters)
    }
    const clearFilters = () => {
        setFilter({
            orderId: '',
            status: '',
            payment: ''
        })
        setFilters({
            orderId: '',
            status: '',
            payment: ''
        })
    }

  return (
    <div className="w-full h-[60px] rounded-xl shadow-lg border border-border overflow-hidden flex">
        <div className="w-full py-2.5 pl-5 flex items-center gap-20">
            <p className="text-sm shrink-0">
                Sipariş adeti: <b>{total}</b>
            </p>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 w-full px-5">
                <span className="max-lg:hidden"></span>
                <Input
                    placeholder="Sipariş No"
                    type="number"
                    value={filters.orderId}
                    onChange={(e) => setFilters({...filters, orderId: e.target.value})}
                />
                <SelectComp
                    label="Sipariş Durumu"
                    placeholder="Sipariş Durumu"
                    data={statusArr.map((v) => ({value: v.id.toString(), name: v.name}))}
                    onValueChange={(e) => setFilters({...filters, status: e})}
                    value={filters.status}
                />
                <SelectComp
                    label="Ödeme Durumu"
                    placeholder="Ödeme Durumu"
                    data={[{value: '0', name: 'Ödeme Başarısız'}, {value: '1', name: 'Ödeme Başarılı'}]}
                    onValueChange={(e) => setFilters({...filters, payment: e})}
                    value={filters.payment}
                />
            </div>
        </div>

        <div className="flex items-center gap-2 px-5 shrink-0 bg-lightBg dark:bg-darkPrimaryLight">
            <button
            onClick={handleFilter}
            className="filter-button">
                <Filter className="text-white"/>
            </button>
            <button 
            onClick={clearFilters}
            className="filter-clear-button">
                <FilterX className="text-white"/>
            </button>
        </div>
    </div>
  )
}

export default Menu